import React, { useEffect, useMemo, useState } from 'react';
import {
  Container,
  LinkContract,
  TitleBox,
  Backdrop,
  Modal,
  ModalActions,
  ModalHeader,
} from './OffersBusinessList.style';
import {
  StatusCircle,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableRowHeader,
} from 'components/ApplicationBusinessList/ApplicationBusinessList.style';
import axios from 'axios';
import { TableData, Request, OffersBusinessListProps } from './OffersBusinessList.interface';
import { Pagination } from 'components/Pagination';
import { formatDate } from 'utils/formatDate';
import { BreadCrumbsBox } from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import Hint from 'components/common/Hint/Hint';
import { default as GroupIconAccept } from 'assets/img/GroupIconAccept.svg';
import { default as GroupIconDecline } from 'assets/img/GroupIconDecline.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { NewButton } from 'components/common/Button';
import { ErrorModal } from 'components/common/ErrorModal';
import SkeletonTable from 'components/SkeletonTable/SkeletonTable';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

export const OffersBusinessList: React.FC<OffersBusinessListProps> = ({
  requestNumber,
  requestId,
  requestedAmount,
  requestPeriod,
  requestRate,
}) => {
  const { id } = useParams<{ id: string }>(); // Получение ID из URL
  const navigate = useNavigate();
  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [isAcceptModalOpen, setAcceptModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Общий loading
  const [error, setError] = useState('');
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [selectedOfferId, setSelectedOfferId] = useState<number | null>(null); // Хранит выбранное предложение
  const [isLoading, setIsLoading] = useState(true); // Состояние загрузки

  const fetchData = () => {
    setIsLoading(true); // Начать загрузку
    axios
      .get<Request[]>(`${BASE_URL}cabinet/creditor/request/offers/${requestId}`, {
        withCredentials: true,
      })
      .then((response) => {
        const transformedData: TableData[] = response.data
          .sort((a: Request, b: Request) => b.id - a.id)
          .map((item: Request) => ({
            id: item.id,
            offerPeriod: `${item.offerPeriod} дней`,
            offerSum: item.offerSum.toLocaleString('ru-KZ', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            dtCreate: formatDate(item.dtCreate),
            offerRate: `${item.offerRate.toLocaleString('ru-KZ', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} %`,
            offerPenaltyRate: `${item.offerPenaltyRate.toLocaleString('ru-KZ', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} %`,
            overpaymentSum: item.overpaymentSum.toLocaleString('ru-KZ', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            organizationName: item.organizationName,
            offerTransfer: `${item.offerTransfer} дней`,
            offerExtra: item.offerExtra,
            organizationType: item.organizationType,
            approvedAmount: item.approvedAmount
              ? item.approvedAmount.toLocaleString('ru-KZ')
              : 'null',
            status: item.statusName,
            contractId: item.contractId,
          }));

        setTableData(transformedData);
      })
      .catch((error) => {
        setError('Ошибка при загрузке данных. Попробуйте позже.');
        setErrorDialogOpen(true);
      })
      .finally(() => {
        setIsLoading(false); // Завершить загрузку
      });
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleAccept = (id: number) => {
    setLoading(true);
    const acceptUrl = `${BASE_URL}cabinet/creditor/request/offers/accept/${id}`;
    axios
      .get(acceptUrl, { withCredentials: true })
      .then((response) => {
        navigate(`/contracts/${response.data.contract}`);
      })
      .catch((error) => {
        console.error('Ошибка принятия предложения:', error);

        // Извлекаем сообщение из ответа сервера
        const errorMessage = error.response?.data?.message || 'Ошибка при принятии предложения.';
        setError(errorMessage); // Устанавливаем текст ошибки
        setErrorDialogOpen(true); // Открываем модальное окно
      })
      .finally(() => {
        setLoading(false);
        setAcceptModalOpen(false); // Закрываем модальное окно принятия
      });
  };

  const handleReject = (id: number) => {
    setLoading(true);
    const rejectUrl = `${BASE_URL}cabinet/creditor/request/offers/reject/${id}`;
    axios
      .get(rejectUrl, { withCredentials: true })
      .then(() => {
        fetchData(); // Обновить данные после отказа
        setRejectModalOpen(false);
      })
      .catch((error) => {
        console.error('Ошибка отказа от предложения:', error);
        const errorMessage = error.response?.data?.message || 'Ошибка при отказе от предложения.';
        setError(errorMessage);
        setErrorDialogOpen(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const offset = currentPage * rowsPerPage;
  const currentTableData = tableData.slice(offset, offset + rowsPerPage);

  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };

  return (
    <Container>
      {isAcceptModalOpen && selectedOfferId !== null && (
        <Backdrop>
          <Modal>
            <ModalHeader>Вы уверены, что хотите принять предложение?</ModalHeader>
            <p>Данное действие переведет сделку в статус Принят</p>
            <ModalActions>
              <NewButton
                onClick={() => handleAccept(selectedOfferId)} // Передача выбранного id
                text='Принять'
                className='green'
                disabled={loading}
              />
              <NewButton
                text='Отменить'
                className='red'
                onClick={() => setAcceptModalOpen(false)}
                disabled={loading}
              />
            </ModalActions>
          </Modal>
        </Backdrop>
      )}
      {isRejectModalOpen && selectedOfferId !== null && (
        <Backdrop>
          <Modal>
            <ModalHeader>Вы уверены, что хотите отказаться от предложения?</ModalHeader>
            <p>Данное действие приведет к отклонению предложения.</p>
            <ModalActions>
              <NewButton
                onClick={() => handleReject(selectedOfferId)} // Передача выбранного id
                text='Отклонить'
                className='red'
                disabled={loading}
              />
              <NewButton
                text='Отменить'
                className='green'
                onClick={() => setRejectModalOpen(false)}
                disabled={loading}
              />
            </ModalActions>
          </Modal>
        </Backdrop>
      )}
      {errorDialogOpen && (
        <ErrorModal
          headerError='Ошибка'
          textError={error}
          buttonClose='Закрыть'
          onClick={() => setErrorDialogOpen(false)} // Закрыть модальное окно
        />
      )}
      <BreadCrumbsBox>
        <p>
          <a href='/applications'>Заявки</a>
        </p>
        <p>{'>'}</p>
        <p>
          <a href={`/req/${requestId}`}>Заявка {requestNumber}</a>
        </p>
        <p>{'>'}</p>
        <p className='select'>Предложения инвесторов</p>
      </BreadCrumbsBox>
      <TitleBox>
        <h2>Предложения</h2>
      </TitleBox>
      {isLoading ? (
        <SkeletonTable /> // Показать скелетон
      ) : (
        <>
          <Table>
            <thead>
              <TableRowHeader>
                <TableHeader width='250px'>
                  <Hint displayText='Моя заявка' text='' />
                </TableHeader>
                <TableHeader className='right'>
                  <Hint displayText='Запр. сумма' text='Моя запрашиваемая сумма' />
                </TableHeader>{' '}
                <TableHeader width='120px' className='right'>
                  <Hint
                    displayText='Срок. фин.'
                    text='Период, на который предоставляется финансирование'
                  />
                </TableHeader>{' '}
                <TableHeader width='130px' className='right'>
                  <Hint displayText='Старт. фин.' text='Начало периода финансирования в днях' />
                </TableHeader>{' '}
                <TableHeader width='80px' className='right'>
                  <Hint displayText='Процент' text='Процентная ставка, применяемая к этой сделке' />
                </TableHeader>
                <TableHeader width='120px' className='right'>
                  <Hint displayText='Пеня/день' text='Штраф за просрочку платежей в день' />
                </TableHeader>{' '}
                <TableHeader className='right'>
                  <Hint displayText='Сум. перепл.' text='Сумма переплаты по вашей заявке' />
                </TableHeader>
                <TableHeader>
                  <Hint displayText='Статус' text='Текущий статус предложения' />
                </TableHeader>
                <TableHeader width='100px'>
                  <Hint displayText='Действие' text='Действие' />
                </TableHeader>{' '}
              </TableRowHeader>
            </thead>
            <tbody>
              <TableRow>
                <TableCell>
                  {' '}
                  <a href={`/req/${requestId}`}>{requestNumber}</a>
                </TableCell>
                <TableCell className='right'>
                  {requestedAmount.toLocaleString('ru-KZ', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{' '}
                  ₸
                </TableCell>
                <TableCell className='right'>{requestPeriod} дней</TableCell>
                <TableCell className='right'></TableCell>
                <TableCell className='right'>
                  {requestRate.toLocaleString('ru-KZ', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{' '}
                  %
                </TableCell>
                <TableCell className='right'></TableCell>
                <TableCell className='right'></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </tbody>
          </Table>
          <Table>
            <thead>
              <TableRowHeader>
                <TableHeader width='250px'>
                  <Hint displayText='Инвестор' text='' />
                </TableHeader>
                <TableHeader className='right'>
                  <Hint displayText='Предл. сумма' text='Предложенная сумма инвестора' />
                </TableHeader>{' '}
                <TableHeader width='120px' className='right'>
                  <Hint
                    displayText='Срок. фин.'
                    text='Период, на который предоставляется финансирование'
                  />
                </TableHeader>{' '}
                <TableHeader width='130px' className='right'>
                  <Hint displayText='Старт. фин.' text='Начало периода финансирования в днях' />
                </TableHeader>{' '}
                <TableHeader width='80px' className='right'>
                  <Hint displayText='Процент' text='Процентная ставка, применяемая к этой сделке' />
                </TableHeader>
                <TableHeader width='120px' className='right'>
                  <Hint displayText='Пеня/день' text='Штраф за просрочку платежей в день' />
                </TableHeader>{' '}
                <TableHeader className='right'>
                  <Hint displayText='Сум. перепл.' text='Сумма переплаты по вашей заявке' />
                </TableHeader>
                <TableHeader>
                  <Hint displayText='Статус' text='Текущий статус предложения' />
                </TableHeader>{' '}
                <TableHeader width='100px'>
                  <Hint displayText='Действие' text='Действие' />
                </TableHeader>{' '}
              </TableRowHeader>
            </thead>
            <tbody>
              {currentTableData.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Hint text={String(row.organizationName)} truncatedLength={34} />
                  </TableCell>

                  <TableCell className='right'>{row.offerSum} ₸</TableCell>
                  <TableCell className='right'>{row.offerPeriod}</TableCell>
                  <TableCell className='right'>{row.offerTransfer}</TableCell>
                  <TableCell className='right'>{row.offerRate}</TableCell>
                  <TableCell className='right'>{row.offerPenaltyRate}</TableCell>
                  <TableCell className='right'>{row.overpaymentSum} ₸</TableCell>
                  <TableCell>
                    <div>
                      <StatusCircle $status={row.status} />
                      <Hint text={row.status} truncatedLength={11} />
                    </div>
                  </TableCell>
                  <TableCell>
                    {row.status === 'Принят' && row.contractId ? (
                      <LinkContract href={`/contracts/${row.contractId}`}>К договору</LinkContract>
                    ) : row.status === 'Активный' ? (
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <img
                          src={GroupIconAccept}
                          alt='Принять'
                          style={{ cursor: 'pointer', width: '18px', height: '18px' }}
                          onClick={() => {
                            setSelectedOfferId(row.id); // Сохранить id
                            setAcceptModalOpen(true); // Открыть модальное окно
                          }}
                        />
                        <img
                          src={GroupIconDecline}
                          alt='Отклонить'
                          style={{ cursor: 'pointer', width: '18px', height: '18px' }}
                          onClick={() => {
                            setSelectedOfferId(row.id);
                            setRejectModalOpen(true);
                          }}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
          <Pagination
            pageCount={Math.ceil(tableData.length / rowsPerPage)}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </Container>
  );
};
