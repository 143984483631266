import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  ApplicationDocumentsListProps,
  ApplicationFile,
} from './ApplicationDocumentsList.interface';
import IconDocuments from 'assets/img/IconDocuments.svg';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import { default as GroupIconDecline } from 'assets/img/GroupIconDecline.svg';
import { default as GroupIconEdit } from 'assets/img/GroupIconEdit.svg';

import {
  Container,
  MainInfoBox,
  FileItem,
  FileLink,
  DeleteIcon,
  ButtonBox,
  EditIcon,
} from './ApplicationDocumentsList.style';
import {
  ExpandableSection,
  MainInfoTitle,
  ArrowIcon,
  MainInfoItemText,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import { DocLinkBox } from '../RequestDetailsNew.style';
import { WarningModal } from 'components/common/WarningModal';
import { useFileDownload } from 'utils/hooks';
import { ErrorModal } from 'components/common/ErrorModal'; // Import ErrorModal

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

const getStatusColor = (status: string) => {
  switch (status) {
    case 'Успешно':
      return 'green';
    case 'Ошибка в формате файла':
      return 'red';
    case 'Отправлен в обработку':
    case 'Принят в обработку':
      return 'orange';
    case 'Файл уже существует':
      return 'red';
    default:
      return 'black'; // Default color for unknown statuses
  }
};

export const ApplicationDocumentsList: React.FC<ApplicationDocumentsListProps> = ({
  files,
  onFilesChange,
  resetFiles,
  docLink,
  requestData,
}) => {
  const [isMainInfoExpanded, setIsMainInfoExpanded] = useState(false);
  const [isMainInfoExpandedBank, setIsMainInfoExpandedBank] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState<ApplicationFile[]>(files ?? []);
  const [warningMessage, setWarningMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [externalLink, setExternalLink] = useState<string | null>(null);
  const { downloadFile } = useFileDownload();
  const fileInputRef = useRef<HTMLInputElement | null>(null); // For handling file input
  const [fileCategory, setFileCategory] = useState<string | null>(null); // For tracking the file category
  const [isUploadingGeneral, setIsUploadingGeneral] = useState(false); // Separate loading state for general files
  const [isUploadingBank, setIsUploadingBank] = useState(false); // Separate loading state for bank statements

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false); // State for ErrorModal
  const [errorDetails, setErrorDetails] = useState({ header: '', text: '' }); // Error details

  useEffect(() => {
    setUploadedFiles(files);
  }, [files]);

  const showErrorModal = (header: string, text: string) => {
    setErrorDetails({ header, text });
    setIsErrorModalOpen(true);
  };

  const handleDeleteFile = async (fileId: string) => {
    try {
      // Make DELETE request to delete the file by its uid and request ID
      await axios.delete(`${BASE_URL}/cabinet/creditor/request/${requestData.id}/${fileId}`, {
        withCredentials: true,
      });

      const updatedFiles = uploadedFiles.filter((file) => file.file !== fileId);
      setUploadedFiles(updatedFiles);
      onFilesChange(updatedFiles);
    } catch (error) {
      console.error('Error deleting file:', error);
      showErrorModal('Ошибка при удалении файла', 'Не удалось удалить файл. Попробуйте позже.');
    }
  };

  const handleEditFile = async (fileId: string, code: string | null) => {
    try {
      // Delete the old file first
      await handleDeleteFile(fileId);

      // Set file category for upload
      setFileCategory(code);

      // Trigger the file input click for uploading a new file
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    } catch (error) {
      showErrorModal('Ошибка при замене файла', 'Не удалось заменить файл. Попробуйте позже.');
    }
  };

  const handleFileUploadWithNewFile = async (file: File, code: string | null) => {
    if (file.size > MAX_FILE_SIZE) {
      setWarningMessage('File size exceeds the 5MB limit.');
      return;
    }

    setWarningMessage('');
    const formData = new FormData();
    formData.append('file', file);

    try {
      if (code === 'BANK_STATEMENT') {
        setIsUploadingBank(true); // Set bank statement loading state
      } else {
        setIsUploadingGeneral(true); // Set general file loading state
      }

      // Build the endpoint dynamically based on whether productFileConfig is provided (for BANK_STATEMENT)
      const url =
        code === 'BANK_STATEMENT'
          ? `${BASE_URL}cabinet/creditor/request/${requestData.id}/BANK_STATEMENT`
          : `${BASE_URL}cabinet/creditor/request/${requestData.id}`;

      const response = await axios.post(url, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const newFile: ApplicationFile = { file: response.data.uid, code, title: file.name };
      const updatedFiles = [...uploadedFiles, newFile];
      setUploadedFiles(updatedFiles);
      onFilesChange(updatedFiles);
    } catch (error) {
      console.error('Error uploading file:', error);
      showErrorModal('Ошибка при загрузке файла', 'Не удалось загрузить файл. Попробуйте позже.');
    } finally {
      if (code === 'BANK_STATEMENT') {
        setIsUploadingBank(false);
      } else {
        setIsUploadingGeneral(false);
      }
    }
  };

  const handleUploadNewFile = (code: string | null) => {
    setFileCategory(code);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      await handleFileUploadWithNewFile(file, fileCategory);
    }
  };

  useEffect(() => {
    if (resetFiles) {
      setUploadedFiles(files);
    }
  }, [resetFiles, files]);

  const handleDocLinkClick = () => {
    setExternalLink(docLink);
    setIsModalOpen(true);
  };

  const handleModalConfirm = () => {
    if (externalLink) {
      window.open(externalLink, '_blank');
    }
    setIsModalOpen(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setExternalLink(null);
  };

  return (
    <Container>
      <input
        type='file'
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileInputChange}
        accept={
          fileCategory === 'BANK_STATEMENT' ? '.pdf' : '.doc,.docx,.pdf,.ppt,.pptx,.xls,.xlsx'
        }
      />

      {/* General Documents Section */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsMainInfoExpanded(!isMainInfoExpanded)}>
          <img src={IconDocuments} alt='icon' />
          Другие документы
          <ArrowIcon src={isMainInfoExpanded ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isMainInfoExpanded && (
          <MainInfoBox>
            {docLink && docLink !== '' && (
              <DocLinkBox>
                <MainInfoItemText>Ссылка на гугл папку</MainInfoItemText>
                <a onClick={handleDocLinkClick} style={{ cursor: 'pointer' }}>
                  {docLink}
                </a>
              </DocLinkBox>
            )}
            {uploadedFiles.length > 0 ? (
              uploadedFiles
                .filter((file) => file.code === null) // General documents
                .map((file) => (
                  <FileItem key={file.file}>
                    <FileLink onClick={() => downloadFile(file.file)}>
                      <img src={IconDocuments} alt='download icon' />
                      {file.title}
                    </FileLink>
                    {requestData.status === 'DRAFT' && (
                      <ButtonBox>
                        <EditIcon onClick={() => handleEditFile(file.file, null)}>
                          <img src={GroupIconEdit} alt='edit icon' />
                        </EditIcon>
                        <DeleteIcon onClick={() => handleDeleteFile(file.file)}>
                          <img src={GroupIconDecline} alt='delete icon' />
                        </DeleteIcon>
                      </ButtonBox>
                    )}
                  </FileItem>
                ))
            ) : (
              <div>Документы отсутствуют</div>
            )}
            {requestData.status === 'DRAFT' && (
              <span style={{ cursor: 'pointer' }} onClick={() => handleUploadNewFile(null)}>
                {isUploadingGeneral ? 'Загрузка...' : '+ Загрузить новый файл'}
              </span>
            )}
          </MainInfoBox>
        )}
      </ExpandableSection>

      {/* Bank Statement Documents Section */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsMainInfoExpandedBank(!isMainInfoExpandedBank)}>
          <img src={IconDocuments} alt='icon' />
          Документы банковской выписки
          <ArrowIcon src={isMainInfoExpandedBank ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isMainInfoExpandedBank && (
          <MainInfoBox>
            {uploadedFiles
              .filter((file) => file.code === 'BANK_STATEMENT')
              .map((file) => (
                <FileItem key={file.file}>
                  <FileLink onClick={() => downloadFile(file.file)}>
                    <img src={IconDocuments} alt='download icon' />
                    {file.title}
                  </FileLink>

                  {requestData.status === 'DRAFT' && file.status && (
                    <span style={{ color: getStatusColor(file.status.name.text_ru) }}>
                      {file.status.name.text_ru}
                    </span>
                  )}

                  {requestData.status === 'DRAFT' && (
                    <ButtonBox>
                      <EditIcon onClick={() => handleEditFile(file.file, 'BANK_STATEMENT')}>
                        <img src={GroupIconEdit} alt='edit icon' />
                      </EditIcon>
                      <DeleteIcon onClick={() => handleDeleteFile(file.file)}>
                        <img src={GroupIconDecline} alt='delete icon' />
                      </DeleteIcon>
                    </ButtonBox>
                  )}
                </FileItem>
              ))}

            {requestData.status === 'DRAFT' && (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => handleUploadNewFile('BANK_STATEMENT')}>
                {isUploadingBank ? 'Загрузка...' : '+ Загрузить новый файл'}
              </span>
            )}

            {uploadedFiles.filter((file) => file.code === 'BANK_STATEMENT').length === 0 && (
              <div>Документы отсутствуют</div>
            )}
          </MainInfoBox>
        )}
      </ExpandableSection>

      {isModalOpen && (
        <WarningModal
          headerWarning='Предупреждение'
          textWarning='Вы переходите на внешнюю ссылку'
          buttonClose='Согласен'
          onClick={handleModalConfirm}
          onClose={handleModalClose}
        />
      )}

      {isErrorModalOpen && (
        <ErrorModal
          headerError={errorDetails.header}
          textError={errorDetails.text}
          buttonClose='Закрыть'
          onClick={() => setIsErrorModalOpen(false)}
        />
      )}
    </Container>
  );
};
