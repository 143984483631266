import React from 'react';
import { default as IphoneGif } from 'assets/img/LoadingEnter1.gif';
import { LoadingContainer, LoadingImage } from './LoadingGif.style';

export const LoadingGif = () => {
  return (
    <LoadingContainer>
      <LoadingImage src={IphoneGif} alt='Загрузка...' />
    </LoadingContainer>
  );
};
