import styled from 'styled-components';

const TitleBox = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
  }
`;

const ContentBox = styled.div`
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border-radius: 4px;
  background: #f6f8fa;
`;

export const ErrorBox = styled.div`
  color: red;
`;

export const PdfViewerContainer = styled.div`
  max-height: 900px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #fff;
`;

export { TitleBox, ContentBox };
