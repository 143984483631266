import './AdminScreen.css';
import { Header } from '../../components/Header';
import { SideBar } from 'components/SideBar';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { InvestorSettings } from 'components/InvestorSettings';
import { UserCabinet } from 'components/UserCabinet';
import { AppProvider, useAppContext } from 'contexts/AppContext';
import ApplicationScreen from 'screens/ApplicationScreen/ApplicationScreen';
import { ApplicationInvestorNew } from 'components/ApplicationInvestor';
import { AdminSettings } from '../../components/AdminSettings/AdminSettings';
import { DocumentsList } from 'components/DocumentsList';
import SuggestionsInvestorList from 'components/SuggestionsInvestorList/SuggestionsInvestorList';
import { ContractBusiness } from 'components/ContractBusiness';
import { NotFound404 } from 'components/NotFoundPage';
import KycNew from 'components/KYC/KycNew';
import { Payments } from 'components/Payments';
import { CabinetAccount } from 'components/CabinetAccount';
import { NewCreateApplication } from 'components/NewCreateApplication';
import { RequestDetailsNew } from 'components/RequestDetailsNew';
import { Groups } from 'components/Groups';
import { GroupDetail } from 'components/Groups/GroupDetail';

const AdminScreen = () => {
  return (
    <AppProvider>
      <AdminScreenContent />
    </AppProvider>
  );
};

const AdminScreenContent = () => {
  const { userDataLight } = useAppContext();
  const navigate = useNavigate();

  const isSpecialUser =
    userDataLight?.status === 'KYC' ||
    userDataLight?.status === 'KYC_IN_PROCESS' ||
    userDataLight?.status === 'KYC_FAIL';

  useEffect(() => {
    if (!userDataLight?.offerSigned) {
      navigate('/organization?tab=agreement');
    }
  }, [userDataLight, navigate]);

  if (isSpecialUser) {
    return (
      <div className='App'>
        <KycNew />
      </div>
    );
  }

  return (
    <div className='App'>
      <SideBar />
      <div className='header'>
        <Header />
        <div className='app1'>
          <Routes>
            <Route path='/' element={<Navigate to='applications' replace />} />
            <Route path='investor-settings' element={<InvestorSettings />} />
            <Route path='applications' element={<ApplicationScreen />} />
            <Route path='user' element={<UserCabinet />} />
            <Route path='organization' element={<CabinetAccount />} />

            {/* Маршрут для инвесторов */}
            {userDataLight?.organizationRole === 'INVESTOR' ? (
              <>
                {/* Маршрут без таба - рендерим mainInfo */}
                <Route path='/request/:id' element={<ApplicationInvestorNew />} />
                {/* Маршрут с табом */}
                <Route path='/request/:id/:tab/:subTab?' element={<ApplicationInvestorNew />} />
              </>
            ) : (
              <Route path='/request/:id' element={<NotFound404 />} />
            )}

            {/* Маршрут для бизнеса */}
            {userDataLight?.organizationRole === 'BUSINESS' ? (
              <>
                {/* Маршрут без таба - рендерим mainInfo */}
                <Route path='/req/:id' element={<RequestDetailsNew />} />
                {/* Маршрут с табом */}
                <Route path='/req/:id/:tab/:subTab?' element={<RequestDetailsNew />} />
                <Route path='/create-application' element={<NewCreateApplication />} />
              </>
            ) : (
              <Route path='/req/:id' element={<NotFound404 />} />
            )}

            {userDataLight?.roles?.includes('ADMIN') ? (
              <Route path='/admin' element={<AdminSettings />} />
            ) : (
              <Route path='/admin' element={<NotFound404 />} />
            )}

            <Route path='/payments' element={<Payments />} />
            <Route path='/contracts' element={<DocumentsList />} />
            <Route path='/groups' element={<Groups />} />
            <Route path='/group/:groupId' element={<GroupDetail />} />
            <Route path='/contracts/:id' element={<ContractBusiness />} />

            {/* Маршруты для предложений */}
            {userDataLight?.organizationRole === 'INVESTOR' ? (
              <>
                <Route path='/suggestions' element={<SuggestionsInvestorList />} />
              </>
            ) : (
              <Route path='/suggestions' element={<NotFound404 />} />
            )}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AdminScreen;
