import axios from 'axios';
import { useEffect, useState } from 'react';
import { CardContainer, Card, CardTitle, CardDescription, EmptyMessage } from './OneStep.style';
import { OneStepProps, Product } from './NewCreateApplication.interface';
import { ErrorModal } from 'components/common/ErrorModal';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

const OneStep: React.FC<OneStepProps> = ({ setCurrentStep, setSelectedProductCode }) => {
  const [data, setData] = useState<Product[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    axios
      .get<Product[]>(`${BASE_URL}ui/products`, {
        withCredentials: true,
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('There was an error fetching the data!', error);
        setError('Произошла ошибка при загрузке данных!');
      });
  }, []);

  const handleProductClick = (code: string) => {
    setSelectedProductCode(code);
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const closeModal = () => {
    setError(null);
  };

  return (
    <>
      <CardContainer>
        {data.length > 0 ? (
          data.map((item) => (
            <Card key={item.code} onClick={() => handleProductClick(item.code)}>
              <CardTitle>{item.name}</CardTitle>
              <CardDescription>{item.description}</CardDescription>
            </Card>
          ))
        ) : (
          <EmptyMessage>Нет доступных продуктов</EmptyMessage>
        )}
      </CardContainer>
      {error && (
        <ErrorModal
          headerError='Ошибка'
          textError={error}
          buttonClose='Закрыть'
          onClick={closeModal}
        />
      )}
    </>
  );
};

export default OneStep;
