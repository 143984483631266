import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { saveAs } from 'file-saver';

import {
  ExpandableSection,
  MainInfoTitle,
  MainInfoBox,
  ArrowIcon,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import { IsPro } from 'components/BankStatement/BankStatement.style';
import {
  FileItem,
  FileLink,
} from 'components/ApplicationInvestor/ApplicationDocumentsList/ApplicationDocumentsList.style';
import { MainInfoContent } from 'components/InfoCreditStory/InfoCreditStory.style';
import { ErrorModal } from 'components/common/ErrorModal';
import { LoadingGif } from 'components/common/LoadingGif';

import IconWorkHistory from 'assets/img/IconWorkHistory.svg';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import IconDocuments from 'assets/img/IconDocuments.svg';
import { pdfjs } from 'react-pdf';
import { PdfViewerContainer } from './PKBInfo.style';

interface PKBInfoProps {
  id: string | number;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

export const PKBInfo: React.FC<PKBInfoProps> = ({ id }) => {
  const [pdfContent, setPdfContent] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isReportExpanded, setIsReportExpanded] = useState(false);
  const [isAdditionalReportExpanded, setIsAdditionalReportExpanded] = useState(false);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}cabinet/investor/request/creditor/abis/${id}/pdf`,
          {
            responseType: 'arraybuffer',
            withCredentials: true,
          },
        );
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        setPdfContent(fileURL);
      } catch (err) {
        setError('Данные отсутствуют');
      } finally {
        setLoading(false);
      }
    };

    fetchPdf();
  }, [id]);

  const handleDownloadPDF = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}cabinet/investor/request/creditor/abis/${id}/pdf`,
        {
          responseType: 'blob',
          withCredentials: true,
        },
      );
      const blob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(blob, `Report_ABIS_${id}.pdf`);
    } catch (err) {
      setError('Ошибка при скачивании, отчет отсутствует');
    }
  };

  const closeErrorModal = () => {
    setError(null);
  };

  if (loading) {
    return <LoadingGif />;
  }

  if (error || !pdfContent) {
    return (
      <div>
        <ExpandableSection>
          <MainInfoTitle>
            <img src={IconWorkHistory} alt='icon' />
            Данные ABIS отсутствуют
          </MainInfoTitle>
        </ExpandableSection>
      </div>
    );
  }

  return (
    <>
      {error && (
        <ErrorModal
          headerError='Произошла ошибка'
          textError={error}
          buttonClose='Закрыть'
          onClick={closeErrorModal}
        />
      )}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsAdditionalReportExpanded(!isAdditionalReportExpanded)}>
          <img src={IconWorkHistory} alt='icon' />
          Скачать расширенный ABIS отчет
          <IsPro>PRO</IsPro>
          <ArrowIcon src={isAdditionalReportExpanded ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isAdditionalReportExpanded && (
          <MainInfoBox>
            <MainInfoContent>
              <FileItem>
                <FileLink onClick={handleDownloadPDF}>
                  <div>
                    <img src={IconDocuments} alt='download icon' />
                    ABYS отчет
                  </div>
                  <p>скачать</p>
                </FileLink>
              </FileItem>
            </MainInfoContent>
          </MainInfoBox>
        )}
      </ExpandableSection>

      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsReportExpanded(!isReportExpanded)}>
          <img src={IconWorkHistory} alt='icon' />
          ABIS отчет
          <IsPro>PRO</IsPro>
          <ArrowIcon src={isReportExpanded ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isReportExpanded && (
          <MainInfoBox>
            <MainInfoContent>
              <PdfViewerContainer>
                <Worker
                  workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                  <Viewer fileUrl={pdfContent} />
                </Worker>
              </PdfViewerContainer>
            </MainInfoContent>
          </MainInfoBox>
        )}
      </ExpandableSection>
    </>
  );
};
