import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  width: 100%; /* Растягиваем контейнер на всю ширину */
  border-radius: 8px;
  background: var(--neutral-50, #fefefe);
  overflow-y: auto;
  max-height: calc(3 * 82px + 2 * 42px);
`;

const Title = styled.h3`
  color: var(--neutral-900, var(--neutral-900, #141414));
  font-family: 'Jost';
  font-size: 18px;
  font-weight: 600;
  line-height: 125%; /* 25px */
`;

const Card = styled.div`
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--neutral-400, #f4f4f4); /* Default style */
  background: var(--neutral-200, #f4f4f4); /* Default style */
  cursor: pointer;
  div {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    p {
      color: var(--neutral-400, var(--neutral-400, #a0a0a0));
      font-family: 'Jost';
      font-size: 14px;
      font-weight: 500;
      line-height: 20px; /* 166.667% */
    }
    span {
      color: var(--neutral-900, var(--neutral-900, #141414));
      font-family: 'Jost';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 125%; /* 15px */
    }
  }
`;

export { Container, Title, Card };
